import type { InjectionKey } from 'vue'
import type { Profile } from '../types'

export const profileKey = Symbol() as InjectionKey<Profile>

export const LOREHEART_PROFILES = [
  {
    name: 'Kaia Loriet',
    title: 'Digital Experience Engineer',
    nav: 'Lore',
    link: 'loriet',
    codepen: 'lawlheart',
    image: 'KL_profile.png',
    avatar: 'KL_avatar.png',
    about: [
      'Passionate problem-solver with deep technical expertise, turning complex ideas into clear, actionable solutions with a strong focus on usability, accessibility, and user experience.',
      'Over 7 years in agile software engineering, crafting precise and secure digital products for healthcare and finance industries.',
      '5 years as a FreeCodeCamp PDX Organizer, fostering collaboration and mentorship in a diverse community.',
    ],
    theme: {
      color: '#1affff',
      accent: '#d45fff',
      backgroundColor: 'rgba(0, 36, 36, 0.90)',
      titleBackgroundColor: 'rgba(0, 36, 36, 0.90)',
      glow: true
    },
    links: [
      {
        name: 'Lawlheart',
        site: 'Codepen',
        url: 'https://codepen.io/lawlheart',
      },
      {
        name: 'Lawlheart',
        site: 'GitHub',
        url: 'https://github.com/Lawlheart',
      },
      {
        name: 'Lawlheart',
        site: 'LinkedIn',
        url: 'https://linkedin.com/in/lawlheart/',
      },
      {
        name: 'Lawlheart',
        site: 'Contra',
        url: 'https://lawlheart.contra.com',
      },
      {
        name: 'Lawlheart',
        site: 'FCC',
        url: 'https://www.freecodecamp.org/lawlheart',
      }
    ],
    certifications: [
      {
        name: "Responsive Web Design",
        prefix: "FE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/kaialoriet/responsive-web-design",
        file: 'freecodecamp_certification_kaialoriet_responsive-web-design',
        tagline: `Responsive Webpages with Flexbox and Grid.`,
        description: `
          The Responsive Web Design Certification teaches the foundational languages
          for building webpages: HTML (Hypertext Markup Language) for content and CSS
          (Cascading Style Sheets) for styling.

          You'll start by creating a cat photo app to grasp HTML and CSS basics.
          As you progress, you'll use modern techniques like CSS variables while
          building a penguin, and follow accessibility best practices while developing
          a quiz site.

          By the end, you'll know how to create responsive webpages that adapt to
          various screen sizes, culminating in a photo gallery using Flexbox and a
          magazine layout with CSS Grid.
        `,
        projects: [
          {
            "certCode": "RD-01",
            "name": "Tribute Page",
            "tagline": "Honoring the Legendary Gorillaz",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Tribute page for Gorrilaz depicting band members",
            "start": "09/02/2021",
            "updated": "09/30/2021",
            "source": "https://codepen.io/lawlheart/pen/MWoemRO",
            "demo": "https://codepen.io/lawlheart/full/MWoemRO"
          },
          {
            "certCode": "RD-02",
            "name": "Survey Form",
            "tagline": "Monster Adoption Agency",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Survey form for adopting cartoon monsters",
            "start": "09/04/2021",
            "updated": "09/07/2021",
            "source": "https://codepen.io/lawlheart/pen/wveyYeN",
            "demo": "https://codepen.io/lawlheart/full/wveyYeN"
          },
          {
            "certCode": "RD-03",
            "name": "Product Landing Page",
            "tagline": "Drink Slurm",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Product landing form for Slurm",
            "start": "09/08/2021",
            "updated": "09/23/2021",
            "source": "https://codepen.io/lawlheart/pen/KKqavGB",
            "demo": "https://codepen.io/lawlheart/full/KKqavGB"
          },
          {
            "certCode": "RD-04",
            "name": "Technical Documentation Page",
            "tagline": "Frontend Tricks and Treats",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Frontend tricks and treats - a halloween themed code demo site",
            "start": "09/14/2021",
            "updated": "09/20/2021",
            "source": "https://codepen.io/lawlheart/pen/eYRGQNL",
            "demo": "https://codepen.io/lawlheart/full/eYRGQNL"
          },
          {
            "certCode": "RD-05",
            "name": "Portfolio",
            "tagline": "Kaia Inception",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Single page portfolio site showcasing latest frontend projects",
            "start": "09/24/2021",
            "updated": "09/28/2021",
            "source": "https://codepen.io/lawlheart/pen/BaZqwxz",
            "demo": "https://codepen.io/lawlheart/full/BaZqwxz"
          }
        ],
      },
      {
        name: "Data Visualization",
        prefix: "LDV",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-data-visualization",
        file: 'freecodecamp_certification_lawlheart_legacy-data-visualization',
        tagline: `Dynamic Interactive Data Visualizations with D3.js`,
        description: `
          The Data Visualization Certification focused on helping me visualize
          complex datasets using D3.js and other JavaScript libraries. Through
          building interactive charts, graphs, and heat maps, I gained the skills
          to present data in visually compelling and meaningful ways. The curriculum
          covered topics like handling dynamic data, creating responsive visualizations,
          and working with large datasets. I also developed the ability to interpret
          data through real-time charts and deliver insights through engaging user
          interfaces.
        `,
        projects: [
          {
            "certCode": "LDV-01",
            "name": "Markdown Previewer",
            "tagline": "Live Markdown Preview",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Markdown preview page built using React",
            "start": "01/02/2016",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-11",
            "demo": "https://codepen.io/lawlheart/pen/vLgmdm",
          },
          {
            "certCode": "LDV-02",
            "name": "Build a Camper Leaderboard",
            "tagline": "Ranking Campers",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Camper leaderboard built in React",
            "start": "01/04/2016",
            "updated": "01/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-12",
            "demo": "https://codepen.io/lawlheart/pen/rxjZEq",
          },
          {
            "certCode": "LDV-03",
            "name": "Build a Recipe Box",
            "tagline": "Recipe Organizer, Local Storage",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Recipe box app built using React",
            "start": "01/19/2016",
            "updated": "01/31/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-13",
            "demo": "https://codepen.io/lawlheart/pen/gPgqGR",
          },
          {
            "certCode": "LDV-04",
            "name": "Game of Life",
            "tagline": "Conway's classic game of life",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Conway's game of life with grid size, generation speed, pause/play, and randomize functions. Built in React on CodePen.",
            "start": "01/31/2016",
            "updated": "09/19/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-14",
            "demo": "https://codepen.io/lawlheart/pen/oboRwo",
          },
          {
            "certCode": "LDV-05",
            "name": "Roguelike Game",
            "tagline": "Venture into the dungeon and rise victorious",
            "skills": "HTML, CSS, JavaScript, React, Vue",
            "description": "Roguelike dungeon game. Players can explore the dungeon, find gear, fight monsters, gain experience, and level up. Defeat the boss to win. Originally built in React, Vue version on CodePen.",
            "start": "01/31/2016",
            "updated": "02/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-15",
            "demo": "https://codepen.io/lawlheart/pen/JGBazL",
          },
          {
            "certCode": "LDV-06",
            "name": "Bar Chart",
            "tagline": "Visualizing US GDP",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Bar chart depicting US GDP built with d3.js",
            "start": "02/04/2016",
            "updated": "02/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-16",
            "demo": "https://codepen.io/lawlheart/pen/bExgVR",
          },
          {
            "certCode": "LDV-07",
            "name": "Scatterplot Graph",
            "tagline": "Doping in Pro Cycling",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Scatterplot graph depicting doping in Professional Bicycle Racing",
            "start": "02/04/2016",
            "updated": "02/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-17",
            "demo": "https://codepen.io/lawlheart/pen/eJLQEQ",
          },
          {
            "certCode": "LDV-08",
            "name": "Heat Map",
            "tagline": "Mapping Exoplanets with NASA Kepler Data",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "D3.js heat map comparing exoplanet radius, mass, and distance from Earth. Utilizes data from NASA Kepler Mission",
            "start": "02/04/2016",
            "updated": "02/12/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-18",
            "demo": "https://codepen.io/lawlheart/pen/wMEbBV",
          },
          {
            "certCode": "LDV-09",
            "name": "Force Directed Map",
            "tagline": "Exploring the Camper Network",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Force directed chart built with d3.js depicting the relationships between campers",
            "start": "02/06/2016",
            "updated": "02/12/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-19",
            "demo": "https://codepen.io/lawlheart/pen/YwJebb",
          },
          {
            "certCode": "LDV-10",
            "name": "Map Data Across the Globe",
            "tagline": "Plotting Global Meteor Impacts",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "D3.js map showing meteor impact sites. The relative size of the point corresponds to the mass of the meteor and the color of the point represents the meteor class.",
            "start": "02/15/2016",
            "updated": "02/15/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-20",
            "demo": "https://codepen.io/lawlheart/pen/VeRjXG",
          }
        ],
      },
      {
        name: "Back End Dev",
        prefix: "LBE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-back-end",
        file: 'freecodecamp_certification_lawlheart_legacy-back-end',
        tagline: `Server-side Dev with Node.js, Express, and MongoDB`,
        description: `
          Learned how to build robust and scalable web applications using JavaScript,
          Node.js, and Express. The curriculum taught me how to create RESTful APIs,
          manage databases, and handle user authentication using technologies like
          MongoDB and Passport.js. 
          I developed various microservices, including URL
          shorteners and file metadata services, to deepen my understanding of server-side
          logic and API interactions. Additionally, I learned how to deploy web apps using
          cloud services, ensuring they were accessible and secure.
        `,
        projects: [
          {
            "certCode": "LBE-01",
            "name": "Timestamp Microservice",
            "tagline": "Convert Dates into Unix and Back",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that checks for a valid date input and returns the date in both unix format and as a readable string",
            "start": "02/16/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/timestamp",
            "demo": "",
          },
          {
            "certCode": "LBE-02",
            "name": "Request Header Parser",
            "tagline": "Dissecting Request Headers",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that responds with data about the request headers",
            "start": "02/18/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/header-parser",
            "demo": "",
          },
          {
            "certCode": "LBE-03",
            "name": "URL Shortener",
            "tagline": "Custom URL Redirects with Ease",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that allows the user to create and use URL redirects.",
            "start": "02/18/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/url-shortener",
            "demo": "",
          },
          {
            "certCode": "LBE-04",
            "name": "Image Search Layer",
            "tagline": "Search Google for Images",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that allows a user to search Google for images. ",
            "start": "02/19/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/image-search",
            "demo": "",
          },
          {
            "certCode": "LBE-05",
            "name": "File Metadata Microservice",
            "tagline": "Analyze File Properties",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint for analysing properties of uploaded files",
            "start": "02/23/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/file-metadata",
            "demo": "",
          },
          {
            "certCode": "LBE-06",
            "name": "Voting App",
            "tagline": "Create, Share, and Vote on Polls",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app Voting app with oAuth that allows users to login, create polls, and share them with others",
            "start": "09/14/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/vote",
            "demo": "",
          },
          {
            "certCode": "LBE-07",
            "name": "Nightlife Coordination App",
            "tagline": "Find Local Restaurants",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that allows the user to search for local bars or restraunts and shows locations and ratings using the Yelp API",
            "start": "09/25/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/nightlife",
            "demo": "",
          },
          {
            "certCode": "LBE-08",
            "name": "Chart the Stock Market",
            "tagline": "Real-Time Market Data",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that charts the stock market using data from the Markit on demand Market Data API",
            "start": "10/10/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/stocks",
            "demo": "",
          },
          {
            "certCode": "LBE-09",
            "name": "Manage a Book Trading Club",
            "tagline": "Organize Book Trades",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that allows users to trade books",
            "start": "12/17/2015",
            "updated": "12/19/2015",
            "source": "https://github.com/Lawlheart/books",
            "demo": "",
          },
          {
            "certCode": "LBE-10",
            "name": "Build a Pinterest Clone",
            "tagline": "Image Sharing Network",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express, Sass",
            "description": "MEAN stack app",
            "start": " Fake quote sharing site inspired by John Oliver’s segment on misappropriated quotes",
            "updated": "04/05/2016",
            "source": "02/24/2016",
            "demo": "https://github.com/Lawlheart/real-quotes",
          }
        ],
      },
      {
        name: "Front End Dev",
        prefix: "LFE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-front-end",
        file: 'freecodecamp_certification_lawlheart_legacy-front-end',
        tagline: `Dynamic Web Apps with HTML, CSS, and JavaScript`,
        description: `
          Created dynamic, interactive user interfaces using HTML, CSS, and JavaScript.
          The curriculum focused on building functional web applications, and I gained
          expertise in manipulating the DOM and handling user events. I learned to work
          with key JavaScript libraries like jQuery, as well as frameworks like AngularJS,
          to create responsive and engaging user experiences. Through projects such as a
          calculator, Tic Tac Toe game, and a Simon game, I was able to apply these skills
          to build real-world applications.
        `,
        projects: [
          {
            "certCode": "LFE-01",
            "name": "Personal Portfolio Webpage",
            "tagline": "Another Kaia Portfolio",
            "skills": "HTML, CSS, JavaScript, Polymer",
            "description": "First Portfolio site, originally built in Polymer and Angular 1, ported to Angular 2",
            "start": "06/15/2015",
            "updated": "09/25/2017",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-01",
            "demo": "https://codepen.io/lawlheart/pen/LpPXBb",
          },
          {
            "certCode": "LFE-02",
            "name": "Random Quote Machine",
            "tagline": "Quote and Share",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site with a button to show a new random quote and another button to tweet it",
            "start": "08/31/2015",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-02",
            "demo": "https://codepen.io/lawlheart/pen/NGWvWe",
          },
          {
            "certCode": "LFE-03",
            "name": "Show the Local Weather",
            "tagline": "Real-Time Weather Updates",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing local weather in C and F, queries weather by zip code",
            "start": "09/01/2015",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-03",
            "demo": "https://codepen.io/lawlheart/pen/bVGmPq",
          },
          {
            "certCode": "LFE-04",
            "name": "Pomodoro Clock",
            "tagline": "Customizable Pomodoro Timer",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site with a working pomodoro style timer with adjustable interjvals",
            "start": "09/02/2015",
            "updated": "09/03/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-04",
            "demo": "https://codepen.io/lawlheart/pen/QjwLrd",
          },
          {
            "certCode": "LFE-05",
            "name": "Use the Twitch.tv JSON API",
            "tagline": "Track Live Streams on Twitch",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing twitch streams, toggleable to show online or offline",
            "start": "09/03/2015",
            "updated": "09/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-05",
            "demo": "https://codepen.io/lawlheart/pen/yYyNEo",
          },
          {
            "certCode": "LFE-06",
            "name": "Stylize Stories on Camper News",
            "tagline": "Latest Camper News Articles",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing cards with links to camper news articles",
            "start": "09/08/2015",
            "updated": "02/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-06",
            "demo": "https://codepen.io/lawlheart/pen/YyXjpo",
          },
          {
            "certCode": "LFE-07",
            "name": "Wikipedia Viewer",
            "tagline": "Search Wikipedia and View Results",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site that queries wikipedia API and shows search links",
            "start": "09/08/2015",
            "updated": "09/09/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-07",
            "demo": "https://codepen.io/lawlheart/pen/avOXPE",
          },
          {
            "certCode": "LFE-08",
            "name": "JavaScript Calculator",
            "tagline": "A Simple Yet Powerful Calculator",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "A working calculator built with JavaScript in AngularJS",
            "start": "09/09/2015",
            "updated": "09/09/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-08",
            "demo": "https://codepen.io/lawlheart/pen/qOOdPV",
          },
          {
            "certCode": "LFE-09",
            "name": "Tic Tac Toe Game",
            "tagline": "Tic Tac Toe Logic, Draw or Win.",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "Working Tic Tac Toe game that *should* never lose, at worst draws",
            "start": "09/11/2015",
            "updated": "02/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-09",
            "demo": "https://codepen.io/lawlheart/pen/pjjLee",
          },
          {
            "certCode": "LFE-10",
            "name": "Simon Game",
            "tagline": "Test your memory",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "Working recreation of the Simon sound and color game with 2 modes.",
            "start": "09/13/2015",
            "updated": "09/13/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-10",
            "demo": "https://codepen.io/lawlheart/pen/RWWzLz",
          }
        ],
      },
    ],
    projects: [
      {
        "name": "PokeLab",
        "tagline": "Crafting the Ultimate Pokémon Multitool",
        "skills": "TypeScript, Vue, Pinia, HTML, CSS, JavaScript",
        "description": "Pokemon Multitool site in development",
        "image": "https://bifrost.loreheart.com/projects/pokelab.png",
        "source": "https://github.com/loreheart/poke-lab",
        "demo": "https://pokelab.loreheart.com"
      },
      {
        "name": "Persona 4 Golden Guide",
        "tagline": "Navigate the labyrinth of Persona 4 Golden",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "A website guide for Persona 4 Golden, initially built using a node.js back end with express and Swig, then migrated to an Angular SPA. CodePen version is in Vue.",
        "image": "https://bifrost.loreheart.com/projects/persona.png",
        "source": "https://github.com/Lawlheart/Persona-4-Golden-Guide",
        "demo": "https://codepen.io/lawlheart/pen/RwZPLVJ"
      },
      {
        "name": "Dungeon Game",
        "tagline": "Venture into the Dungeon and Rise Victorious.",
        "skills": "HTML, CSS, JavaScript, React",
        "description": "Roguelike dungeon game. Players can explore the dungeon, find gear, fight monsters, gain experience, and level up. Defeat the boss to win.",
        "image": "https://bifrost.loreheart.com/projects/fcc/Lawlheart/roguelike.png",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-15",
        "demo": "https://codepen.io/lawlheart/pen/JGBazL"
      },
      {
        "name": "Pokedex",
        "tagline": "Explore the Pokémon World with an Interactive Pokedex.",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "Pokedex site with a working IV calculator (not active in Vue Codepen version yet). Built using AngularJS, Sass, Bourbon, and PokeApi. CodePen version is in Vue.",
        "image": "https://bifrost.loreheart.com/projects/pokedex.png",
        "source": "https://github.com/Lawlheart/pokedex",
        "demo": "https://codepen.io/lawlheart/pen/GREbjLJ"
      },
      {
        "name": "Fallout Terminal Game",
        "tagline": "Relive the Fallout Hacking Minigame.",
        "skills": "HTML, CSS, JavaScript, AngularJS",
        "description": "Recreation of the hacking minigame in Fallout 4.",
        "image": "https://bifrost.loreheart.com/projects/fallout.png",
        "source": "https://github.com/Lawlheart/fallout-terminal",
        "demo": "https://codepen.io/lawlheart/pen/eJOZjN"
      },
      {
        "name": "Meteor Strike Map",
        "tagline": "Visualizing Earth's Ancient Impacts.",
        "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
        "description": "D3.js map showing meteor impact sites. The relative size of the point corresponds to the mass of the meteor and the color of the point represents the meteor class.",
        "image": "https://bifrost.loreheart.com/projects/fcc/Lawlheart/meteors.png",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-20",
        "demo": "https://codepen.io/lawlheart/pen/VeRjXG"
      },
      {
        "name": "Exoplanet Heat Map",
        "tagline": "Charting the Cosmos: Visualize Exoplanet Data.",
        "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
        "description": "D3.js heat map comparing exoplanet radius, mass, and distance from Earth. Utilizes data from NASA Kepler Mission",
        "image": "https://bifrost.loreheart.com/projects/fcc/Lawlheart/exoplanets.png",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-18",
        "demo": "https://codepen.io/lawlheart/pen/wMEbBV"
      },
      {
        "name": "Conway's Game of Life",
        "tagline": "Bringing Conway's Game of Life to life.",
        "skills": "HTML, CSS, JavaScript, React",
        "description": "Conway's game of life with grid size, generation speed, pause/play, and randomize functions. Built in React on CodePen.",
        "image": "https://bifrost.loreheart.com/projects/fcc/Lawlheart/life.png",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-14",
        "demo": "https://codepen.io/lawlheart/pen/oboRwo"
      },
      {
        "name": "Civilization V Leaders",
        "tagline": "Civilization V Strategy at a Glance.",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "A Reference Page for Civilization V with all of the available leaders in the base game and DLCs that shows each leader\"s unique ability, units, and buildings.",
        "image": "https://bifrost.loreheart.com/projects/civilization.jpg",
        "source": "https://github.com/Lawlheart/civ-leaders",
        "demo": "https://codepen.io/lawlheart/pen/QWMNKmg"
      }
    ],
    experiences: [
      {
        "title": "Artemis Console",
        "subtitle": "Healthcare Data Visualization Platform",
        "tagline": "Healthcare Insights with Intuitive Data Visualization.",
        "description": "Developed a robust data visualization platform, allowing employers to analyze healthcare ROI and optimize their benefits strategies. Artemis Console enables companies to dive deep into healthcare data, presenting key insights in a user-friendly, accessible format.",
        "role": "Senior Frontend Engineer",
        "tools": "ECharts, Angular, TypeScript, SCSS",
        "topics": "Data Visualization, Frontend Development, UX/UI, Healthcare Data Analytics",
        "industry": "Healthcare Technology",
        "image": "https://bifrost.loreheart.com/work/console.png",
        "link": "https://www.artemishealth.com/console",
        "contra": "https://lawlheart.contra.com/p/OR67LkNk-artemis-console-healthcare-analysis-dashboard",
      },
      {
        "title": "FINRA DXT",
        "subtitle": "Digital Experience Transformation",
        "tagline": "Transforming Digital Experiences for the Financial Future.",
        "description": "Contributed to FINRA's multi-year Digital Experience Transformation (DXT), creating seamless digital solutions for brokerage firms to interact with regulatory systems. Focused on improving the overall user experience, performance, and accessibility of the platform while ensuring compliance with strict regulatory standards.",
        "role": "Frontend Engineer",
        "tools": "Angular, JavaScript, TypeScript, Rx.js, SCSS, Jenkins",
        "topics": "Regulatory Compliance, User Experience (UX), Digital Transformation, Accessibility",
        "industry": "FinTech",
        "image": "https://bifrost.loreheart.com/work/dxt.png",
        "link": "https://www.finra.org/filing-reporting/dxt",
        "contra": "https://lawlheart.contra.com/p/7ALALFZU-finras-digital-experience-transformation-dxt"
      },
      {
        "title": "Northwell Health dPx",
        "subtitle": "Digital Patient Experience",
        "tagline": "Seamless Healthcare, Patient-Centric Design.",
        "description": "Worked on Northwell Health's Digital Patient Experience (dPx) initiative, contributing to the development of a React Native app designed to streamline patient engagement and improve access to healthcare services. Focused on building intuitive user interfaces and ensuring the app met healthcare industry standards for accessibility and security.",
        "role": "Frontend Engineer",
        "tools": "React Native, React, JavaScript, TypeScript, CI, SCSS",
        "topics": "Healthcare, Patient Engagement, Mobile App Development, Accessibility",
        "industry": "Healthcare Technology",
        "image": "https://bifrost.loreheart.com/work/nwh.png",
        "link": "https://www.phase2technology.com/case-study/northwell-health-dpx",
        "contra": "https://lawlheart.contra.com/p/3PNW3Po7-northwell-healths-digital-patient-experience"
      },
      {
        "title": "Splunk DSA",
        "subtitle": "Data Source Assessment",
        "tagline": "Smarter Data Insights with Interactive Visualizations.",
        "description": "Provided frontend development support for Splunk's Data Source Assessment, focusing on creating interactive data visualization tools and improving the user interface. Worked on key features including dynamic filters for pivot tables, value-tracking forms, and optimizing the data representation for better decision-making insights.",
        "role": "Frontend Engineer, Full Stack Engineer",
        "tools": "Angular, JavaScript, TypeScript, SCSS, Jest",
        "topics": "Data Visualization, Frontend Development, User Interface (UI), Enterprise Solutions, Testing",
        "industry": "Data Analytics & Enterprise Software",
        "image": "https://bifrost.loreheart.com/work/splunk.png",
        "link": "https://conf.splunk.com/files/2017/slides/drive-more-value-through-data-source-and-use-case-optimization.pdf",
        "contra": "https://lawlheart.contra.com/p/mlFZ2UqR-splunk-data-source-assessment-app"
      },
      {
        "title": "Major League Soccer EX",
        "subtitle": "Dynamic Editorial Experience",
        "tagline": "Editorial Workflows for Sports at its Finest.",
        "description": "Developed and optimized editorial workflows for Major League Soccer (MLS), creating a dynamic component library to streamline content creation and management processes. Worked closely with editorial teams to prototype tools that improved the efficiency and flexibility of their content management system, enhancing the overall user experience for content creators.",
        "role": "Frontend Engineer",
        "tools": "React, JavaScript, Gatsby, SCSS",
        "topics": "Editorial Workflows, Component Libraries, Content Management, User Experience (UX), Prototyping",
        "industry": "Sports & Entertainment",
        "image": "https://bifrost.loreheart.com/work/mls.png",
        "link": "https://www.phase2technology.com/case-study/major-league-soccer",
        "contra": "https://lawlheart.contra.com/p/gqAZFNcQ-major-league-soccers-dynamic-editorial-experience"
      },
      {
        "title": "Johnson & Johnson SBS",
        "subtitle": "Multi-Brand Site Building System",
        "tagline": "A unified platform for 400+ Global Brands.",
        "description": "Contributed to the development of a multisite system for Johnson & Johnson (J&J), streamlining editorial workflows for over 400 global brands. Focused on building scalable frontend components and ensuring consistency across multiple brand sites, improving content management efficiency and delivering a cohesive user experience.",
        "role": "Frontend Engineer",
        "tools": "Vue, JavaScript, Nuxt, SCSS",
        "topics": "Multisite Systems, Frontend Development, Editorial Workflows, Content Management, Prototyping",
        "industry": "Pharmaceuticals & Consumer Health, Marketing",
        "image": "https://bifrost.loreheart.com/work/jnj.png",
        "link": "https://www.phase2technology.com/case-study/jnj",
        "contra": "https://lawlheart.contra.com/p/srBcXoLO-johnson-and-johnsons-multi-brand-site-building-system"
      },
      {
        "title": "Phase2 Rebrand",
        "subtitle": "Agency Site Rebrand",
        "tagline": "A modern, Responsive Rebrand.",
        "description": "Contributed to the rebranding and redevelopment of Phase2's agency website, focusing on creating a visually appealing and user-friendly digital experience. Worked closely with designers and stakeholders to implement a responsive, accessible website that effectively showcased Phase2's services and expertise in digital transformation.",
        "role": "Frontend Engineer",
        "tools": "JavaScript, Lit, WebComponents, SCSS",
        "topics": "Rebranding, User Interface (UI), Responsive Design, Accessibility",
        "industry": "Digital Agency, Technology, Marketing",
        "image": "https://bifrost.loreheart.com/work/rebrand-article.png",
        "link": "https://www.phase2technology.com/blog/web-components-our-journey-from-lit-to-stencil-and-back-again"
      },
      // {
      //   "CODE": "DGA",
      //   "client": "SIMB",
      //   "title": "Project D",
      //   "subtitle": "Alumni Site",
      //   "role": "Full Stack",
      //   "duration": "1M",
      //   "start": "12-04-2017",
      //   "end": "01-15-2018",
      //   "scope": "Development",
      //   "tools": "JavaScript, Ruby, Rails",
      //   "topics": "CMS",
      //   "industry": "Education",
      //   "about": "Provide support and updates to existing rails alumni site",
      //   "image": "",
      //   "link": ""
      // },
      // {
      //   "CODE": "BCTS",
      //   "client": "SIMB",
      //   "title": "Project B",
      //   "subtitle": "Candidate Management System",
      //   "role": "Full Stack",
      //   "duration": "1Y",
      //   "start": "05-31-2017",
      //   "end": "05-07-2018",
      //   "scope": "Development",
      //   "tools": "JavaScript, Ruby, Rails",
      //   "topics": "CMS",
      //   "industry": "Tech",
      //   "about": "Extended and maintained existing candidate management system in rails",
      //   "image": "",
      //   "link": ""
      // },
      // {
      //   "CODE": "MCE",
      //   "client": "SIMB",
      //   "title": "Project A",
      //   "subtitle": "Dashboard and Panels",
      //   "role": "Frontend",
      //   "duration": "3M",
      //   "start": "12-04-2017",
      //   "end": "03-26-2018",
      //   "scope": "Development",
      //   "tools": "JavaScript, Angular",
      //   "topics": "DI",
      //   "industry": "Tech",
      //   "about": "Implemented dashboard and panel implementation based on design wireframes",
      //   "image": "",
      //   "link": ""
      // },
      // {
      //   "CODE": "Radar",
      //   "client": "PHAS",
      //   "title": "Project R",
      //   "subtitle": "Tech Forecast Tool",
      //   "role": "Full Stack",
      //   "duration": "4M",
      //   "start": "06-01-2020",
      //   "end": "10-02-2020",
      //   "scope": "Development",
      //   "tools": "JavaScript, React, GraphQL",
      //   "topics": "CMS, DI",
      //   "industry": "Tech",
      //   "about": "Developed an internal tool that is to be used for hiring and management decisions for a medium sized software agency",
      //   "image": "",
      //   "link": ""
      // },
    ],
    accolades: [ 
      {
        author: 'Christopher Bloom',
        context: 'Mentor and Former Manager',
        title: 'Former Director Of Engineering, Frontend at Phase2',
        link: 'https://www.linkedin.com/in/illepic',
        short: `
          Kaia is an absolute savant developer, with world-class
          mastery of multiple programming languages and paradigms
        `,
        full: `
          I work around Kaia almost weekly during coding meetups
          in Portland, OR. Kaia is an absolute savant developer,
          with world-class mastery of multiple programming languages
          and paradigms. They're a master at Javascript/Node, Ruby,
          and most other "frontend" tech.
          Have you seen their portfolio? It's incredible and really
          shows their passion and flexibility in learning new frameworks
          while putting them to use. Kaia is gracious and professional,
          and is motivated to get the job done in the best way possible.
          They are an active member in multiple programming communities,
          while also teaching and guiding new learners.
          I would hire them in an instant if my company was hiring!
        `
      },
      {
        author: 'Joe Karasek',
        context: 'Colleague',
        title: 'Platform Owner, Design Systems at Nike',
        link: 'https://www.linkedin.com/in/joe-karasek',
        short: `
          Kaia was always there at the weekly Mentorship Saturday coding
          meetup with a huge smile helping whomever they could.
        `,
        full: `
          I first met Kaia more than a year ago at the weekly Mentorship
          Saturday coding meetup. I try to go nearly every week, and they
          were always there with a huge smile helping whomever they could.
          It is clear to me that Kaia has a passion to build professional
          quality applications and websites, explore the world of technology,
          and support those around them. As a developer, Kaia is highly qualified
          to work with various frameworks, learn new ones quickly, and be an
          invaluable asset to any team or company. I would not hesitate to give
          my full recommendation of Kaia.
        `
      },
      {
        author: 'Caitlin Halla',
        context: 'Former Classmate',
        title: 'Developer Evangelist, Observability as Splunk',
        link: 'https://www.linkedin.com/in/caitlinhalla',
        short: `
          During our time together at Epicodus, Kaia was always
          enthusiastic and willing to help anyone with
          their problem solving needs.
        `,
        full: `
          Kaia's depth of knowledge when it comes to computer programming
          is quite impressive. During our time together at Epicodus, they
          took on a teacher's assistant role, as classmates would frequently
          go to them with coding related questions. They were always
          enthusiastic and willing to help anyone with their problem solving
          needs. Their projects, both class and personal, clearly speak for
          themselves, and their ability to learn and execute new skills on
          their own would be an asset to any company. 
        `
      }, 
      {
        author: 'Falk Schwiefert',
        context: 'Former Classmate',
        title: 'Engineering Manager at SoSafe',
        link: 'https://www.linkedin.com/in/falkschwiefert',
        short: `
          Kaia is extremely knowledgeable - pair programming with them was
          awesome, both as driver and as navigator.
        `,
        full: `
          Kaia is extremely knowledgeable. This is the first thing that
          comes to mind about them. There are few things around JavaScript
          (and by now some other languages) they don't know. In addition to
          that, when they don't know something they are extremely dedicated
          in their work to fill any knowledge gaps as quickly and as
          thoroughly as possible. 
          I loved studying and working on projects with Kaia - pair
          programming with them was awesome, both as driver and as navigator.
          They are able to not only research and pick up concepts quickly, they
          can also teach them and mentor others. And in all of that they remain
          open to coaching and learning more. They walk the fine line between
          the most efficient code and readability with confidence and skill. 
          If I had a job to offer, Kaia would definitely be on my short list.
        `
      }, 
      {
        author: 'Michael Johnson',
        context: 'Non-Profit Project Team Leader',
        title: 'Former COO of FreeCodeCamp',
        link: 'https://www.linkedin.com/in/michaeldjohnsondc',
        short: `
          Kaia is a gifted web developer, a reliable team member, and
          I sincerely hope to work with them again in the future.
          Our client loved them.
        `,
        full: `
          I worked closely with Kaia on a project for a Colombian-based
          nonprofit organization. Our group, distributed throughout three
          countries, delivered a custom CMS based on KeystoneJS. Kaia is
          a gifted web developer and a reliable team member, and I sincerely
          hope to work with them again in the future. Our client loved them.
        `
      }, 
      {
        author: 'Matthew Hoselton',
        context: 'Former Classmate',
        title: 'Web Developer',
        link: 'https://www.linkedin.com/in/matthew-hoselton-256334b8',
        short: `
          Over the course of a year, I witnessed them mentor fellow code campers,
          finish the FreeCodeCamp curriculum, and grock most of the mainstream
          JS libs.
        `,
        full: `
          I got to know Kaia through PDX's FreeCodeCamp meetup. Over the
          course of a year, I witnessed them mentor fellow code campers,
          finish the FreeCodeCamp curriculum, and grock most of the mainstream
          JS libs. Kaia is a solid hacker and all around nice person.
        `
      }, 
      {
        author: 'Marty Kovach',
        context: 'Former Classmate',
        title: 'Epicodus Student',
        link: 'https://www.linkedin.com/in/marty-kovach-tye105',
        short: `
          They are a great collaborator to bounce ideas of off and very skilled
          when it comes to troubleshooting and quashing bugs!
        `,
        full: `
          I have worked with Kaia on numerous school projects at Epicodus. Their
          approach to writing code is very methodical, and is a great collaborator
          to bounce ideas of off. Additionally they are very skilled when it comes
          to trouble shooting and quashing bugs! I greatly recommend them to any
          future employers.
        `
      }
    ]
  }, {
    name: 'RJ Urquhart',
    title: 'Digital Accessibility Analyst',
    nav: 'RJ',
    link: 'rj',
    codepen: 'raehart',
    image: 'RJ_profile.png',
    avatar: 'RJ_avatar.png',
    about: [
      'UX Web Designer and community-focused leader with a passion for creating accessible and inclusive digital experiences.',
      'Strong background in front-end development and a deep commitment to diversity and inclusion, I work to bridge the gap between design and usability.',
      'Goal is to make the digital world more welcoming and usable for everyone, regardless of their abilities.'
    ],
    theme: {
      color: '#FF61FF',
      accent: '#0BD486',
      backgroundColor: 'rgba(42, 16, 60, 0.90)',
      titleBackgroundColor: 'rgba(42, 16, 60, 0.90)',
      glow: true
    },
    links: [
      {
        name: 'raehart',
        site: 'Codepen',
        url: 'https://codepen.io/raehart',
      },
      {
        name: 'raehart',
        site: 'GitHub',
        url: 'https://github.com/raehart',
      },
      {
        name: 'rj-urquhart',
        site: 'LinkedIn',
        url: 'https://linkedin.com/in/rj-urquhart/',
      }, 
      {
        name: 'raeheart',
        site: 'Contra',
        url: 'https://raeheart.contra.com',
      }, 
      {
        name: 'rjuqruhart',
        site: 'FCC',
        url: 'https://www.freecodecamp.org/rjurquhart',
      }
    ],
    certifications: [
      {
        name: "Responsive Web Design",
        prefix: "RD",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/rjurquhart/responsive-web-design",
        file: 'freecodecamp_certification_rjurquhart_responsive-web-design',
        tagline: `Responsive Webpages with Flexbox and Grid.`,
        description: `
          The Responsive Web Design Certification teaches the foundational languages
          for building webpages: HTML (Hypertext Markup Language) for content and CSS
          (Cascading Style Sheets) for styling.

          You'll start by creating a cat photo app to grasp HTML and CSS basics.
          As you progress, you'll use modern techniques like CSS variables while
          building a penguin, and follow accessibility best practices while developing
          a quiz site.

          By the end, you'll know how to create responsive webpages that adapt to
          various screen sizes, culminating in a photo gallery using Flexbox and a
          magazine layout with CSS Grid.
        `,
        projects: [
          {
            "certCode": "RD-01",
            "name": "Tribute Page",
            "tagline": "Gorrilaz",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Tribute page for Gorrilaz depicting band members",
            "start": "09/02/2021",
            "updated": "09/24/2021",
            "source": "https://codepen.io/raehart/full/BaZzRXz",
            "demo": "https://codepen.io/raehart/pen/BaZzRXz"
          },
          {
            "certCode": "RD-02",
            "name": "Survey Form",
            "tagline": "Adventurers Wanted",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Virtual Role Playing Game Sign-Up Sheet",
            "start": "09/04/2021",
            "updated": "09/24/2021",
            "source": "https://codepen.io/raehart/pen/mdwrpWw",
            "demo": "https://codepen.io/raehart/pen/mdwrpWw"
          },
          {
            "certCode": "RD-03",
            "name": "Product Landing Page",
            "tagline": "Mystery Shack Internet Thingy",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Shop page for the Mystery Shack",
            "start": "09/08/2021",
            "updated": "11/03/2021",
            "source": "https://codepen.io/raehart/full/RwgKLoL",
            "demo": "https://codepen.io/raehart/pen/RwgKLoL"
          },
          {
            "certCode": "RD-04",
            "name": "Technical Documentation Page",
            "tagline": "RJ Investigates",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Documentation page exploring popular programming languages",
            "start": "09/14/2021",
            "updated": "11/12/2021",
            "source": "https://codepen.io/raehart/full/GREMYqb",
            "demo": "https://codepen.io/raehart/pen/GREMYqb"
          },
          {
            "certCode": "RD-05",
            "name": "Portfolio",
            "tagline": "RJ's Responsive Web Portfolio",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Portfolio for RJ with all 4 other responsive design projects",
            "start": "09/24/2021",
            "updated": "8/6/2024",
            "source": "https://codepen.io/raehart/full/qBjJPWK",
            "demo": "https://codepen.io/raehart/pen/qBjJPWK"
          }
        ],
      }
    ],
    projects: [
      {
        "name": "PokeLab",
        "skills": "Design, UX, Wireframing",
        "tagline": "Pokémon Multitool Site",
        "description": "Pokemon Multitool site in development",
        "image": "https://bifrost.loreheart.com/projects/pokelab.png",
        "source": "https://github.com/loreheart/poke-lab",
        "demo": "https://pokelab.loreheart.com"
      },
      {
        "name": "Pokedex",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "tagline": "Our First Pokedex",
        "description": "Pokedex site orignally built using AngularJS, Sass, and PokeApi. CodePen version is in Vue.",
        "image": "https://bifrost.loreheart.com/projects/pokedex.png",
        "source": "https://github.com/raehart/pokedex",
        "demo": "https://codepen.io/raehart/pen/zYdGKvX"
      },
      {
        "name": "RJ Investigates",
        "skills": "Responsive Design, JavaScript, HTML, CSS",
        "tagline": "Exploring Popular Programming Languages",
        "description": "Documentation page exploring popular programming languages",
        "image": "https://bifrost.loreheart.com/projects/fcc/RJ/fcc-04-documentation-rji.jpeg",
        "source": "https://codepen.io/raehart/pen/GREMYqb",
        "demo": "https://codepen.io/raehart/full/GREMYqb"
      },
      {
        "name": "Portfolio - RJ",
        "skills": "Responsive Design, JavaScript, HTML, CSS",
        "tagline": "RJ's Responsive Web Design Projects",
        "description": "Portfolio made as part of the Responsive Web Design FreeCodeCamp certificate with all 4 other responsive design projects",
        "image": "https://bifrost.loreheart.com/projects/fcc/RJ/fcc-05-portfolio-rj.jpeg",
        "source": "https://codepen.io/raehart/pen/qBjJPWK",
        "demo": "https://codepen.io/raehart/full/qBjJPWK"
      }
    ],
    experiences: [
      {
        "title": "Phase2",
        "subtitle": "Particle Design System",
        "tagline": "Components for Scalable Design Systems",
        "description": "Contributed to the development and documentation of the Particle Design System, enabling rapid prototyping and scalable solutions across multiple platforms. Implemented reusable components using Atomic Design principles and ensured seamless integration with platforms like Drupal and Pattern Lab.",
        "role": "Front-End Developer & Documentation Specialist",
        "tools": "Twig, Sass, JavaScript, Webpack, Bootstrap 4, Pattern Lab",
        "topics": "UX, UI, Design Systems, Atomic Design, Documentation",
        "industry": "Technology",
        "image": "https://bifrost.loreheart.com/work/astrogoat.png",
        "link": "https://phase2.gitbook.io/frontend/particle/intro",
        "contra": "https://raeheart.contra.com/p/evP3OcOb-particle-design-system"
      },
      {
        "title": "Splunk",
        "subtitle": "Center of Excellence Testing Automation",
        "tagline": "Comprehensive Test Automation.",
        "description": "Developed comprehensive testing automation for Splunk's Center of Excellence, ensuring full test coverage and improving system performance. Enhanced testing models and controllers to ensure reliable, maintainable code, contributing to smoother deployments and operational efficiency.",
        "role": "Test Automation Developer",
        "tools": "JavaScript, Mocha, Chai, Docker",
        "topics": "Testing Automation, Code Coverage, Quality Assurance, Debugging",
        "industry": "Technology, Data",
        "image": "https://bifrost.loreheart.com/work/splunk-coe.png",
        "link": "https://conf.splunk.com/files/2017/slides/operationalize-a-splunk-center-of-excellence-coe.pdf",
      },
      {
        "title": "Northwell Health",
        "subtitle": "Digital Patient Experience (dPx)",
        "tagline": "Improved patient Experience",
        "description": "Contributed to the development of a component-based design system for Northwell Health's Digital Patient Experience, improving accessibility and user engagement for millions of users. Focused on search functionalities, appointment booking, and billing interfaces, ensuring compliance with accessibility standards.",
        "role": "Accessibility-Focused Front-End Developer",
        "tools": "HTML, CSS, JavaScript, Sass, Pattern Lab, Drupal, Particle",
        "topics": "UX, Accessibility, Component-Based Design, Healthcare Solutions",
        "industry": "Healthcare",
        "image": "https://bifrost.loreheart.com/work/nwh.png",
        "link": "https://www.phase2technology.com/case-study/northwell-health-dpx",
        "contra": "https://raeheart.contra.com/p/223osEeR-northwell-healths-digital-patient-experience-transformation"
      },
      {
        "title": "AdventHealth",
        "subtitle": "Unified Digital Experience",
        "tagline": "An Accessible Digital Platform for 1,200 Facilities.",
        "description": "Developed a scalable, accessible digital platform for AdventHealth, unifying 1,200 facilities under one digital ecosystem. Improved user engagement and accessibility, reducing microsite launch times and enhancing patient interaction through real-time appointment availability.",
        "role": "Accessibility-Focused Front-End Developer",
        "tools": "HTML, CSS, JavaScript, Sass, Pattern Lab, Drupal, Particle",
        "topics": "UX, Accessibility, Component-Based Design, Healthcare Solutions",
        "industry": "Healthcare",
        "image": "https://bifrost.loreheart.com/work/advent.png",
        "link": "https://www.phase2technology.com/case-study/adventhealth",
        "contra": "https://raeheart.contra.com/p/3mXAEQAQ-advent-healths-unifying-digital-experience"
      },
      {
        "title": "FreeCodeCamp",
        "subtitle": "JAMstack Hackathon PDX Satellite Edition",
        "tagline": "PDX Edition of the Global JAMstack Hackathon",
        "description": "Organized and facilitated the PDX edition of the global freeCodeCamp JAMstack Hackathon, fostering developer engagement and promoting accessible web development through fast-paced, collaborative events.",
        "role": "Community Organizer & Technology Evangelist",
        "tools": "JavaScript, HTML, CSS, JAMstack, GitHub",
        "topics": "Web Development, Community Engagement, Accessibility, Hackathons",
        "industry": "Technology",
        "image": "https://bifrost.loreheart.com/work/jamstack.png",
        "link": "https://www.freecodecamp.org/news/winners-from-the-2018-freecodecamp-jamstack-hackathon-at-github-2a39bd1db878/",
        "contra": "https://raeheart.contra.com/p/O3PZsP7v-free-code-camp-ja-mstack-hackathon-pdx-satellite-edition"
      },
      {
        "title": "Design Portland",
        "subtitle": "Empathy in a World with No Averages Workshop",
        "tagline": "Accessibility and Empathy Through Hands-on Exercises.",
        "description": "Led a sold-out workshop at Design Portland, using hands-on empathy-building exercises to simulate disabilities and teach designers to create more accessible digital solutions.",
        "role": "Organizer & Accessibility Specialist",
        "tools": "Empathy Exercises, Accessibility Tools",
        "topics": "Accessibility, UX, Inclusive Design, Empathy Building",
        "industry": "Design",
        "image": "https://bifrost.loreheart.com/work/empathy-workshop.jpg",
        "link": "",
        "contra": "https://raeheart.contra.com/p/3QW2POe7-design-week-portlands-empathy-in-a-world-with-no-averages"
      }
      // {
      //   "CODE": "UNI",
      //   "client": "UNI",
      //   "title": "Project U",
      //   "subtitle": "Mobile Site Builder",
      //   "role": "Developer",
      //   "start": "04-01-2022",
      //   "end": "06-30-2022",
      //   "scope": "Development",
      //   "tools": "JavaScript, HTML5, CSS3",
      //   "topics": "Accessbility, User Experience, User Interface",
      //   "industry": "Tech, Design",
      //   "about": "Helped develop a site building system.",
      // },
    ],
    accolades: [
      {
        author: 'Caito Scherr',
        context: 'Hackathon Teammate',
        title: 'Developer Advocate at StreamNative',
        link: 'https://www.linkedin.com/in/caito-scherr-80aa09b6/',
        short: `
          I worked closely with RJ on a team for a 3 day Hackathon.
          Our whole team benefited from their problem-solving
          skills and collaborative nature.
        `,
        full: `
          I had the chance to work closely with RJ on a
          team for a 3 day Hackathon. Our whole team benefited
          from their problem-solving skills and collaborative
          nature. RJ's attention to detail and ability to
          communicate effectively about their programming
          solutions helped us progress productively on our
          application. Beyond this experience, RJ always seeks
          out opportunities for learning new technologies and
          staying connected with their local tech community. 
          RJ would be a valuable asset to any team looking for
          someone who is engaged, passionate, and is an
          efficient collaborator and contributor.
        `
      }, 
      {
        author: 'Heath Mitchell',
        context: 'Resident Assistant Colleague',
        title: 'Action Officer at FEMA',
        link: 'https://www.linkedin.com/in/heathrmitchell',
        short: `
          RJ's ability to assess community needs and then
          engage in effective community outreach were second
          to none.
        `,
        full: `
          During the 2 years I worked with RJ as a Resident
          Assistant. RJ displayed extensive skills at event
          planning that brought together some particularly
          difficult communities to program for. RJ's ability
          to assess community needs and then engage in
          effective community outreach in this environment
          were second to none.
        `
      }
    ]
  }
]

export const profileDefault: Profile = LOREHEART_PROFILES[0]